import ReactDOM from "react-dom/client";
import {redirectUrl} from "./utils";

const tokenUrl = "https://keycloak.snowstar.org/realms/RingLink/protocol/openid-connect/token";

function App() {
    const param = new URLSearchParams(window.location.search);

    const code = param.get("code");
    if (code === null) {
        return (
            <div>
                登录失败
            </div>
        );
    } else {
        (async () => {
            try {
                let resp = await fetch(tokenUrl, {
                    method: "post",
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    },
                    body: `grant_type=authorization_code&redirect_uri=${redirectUrl}&client_id=frontend&code=${code}`
                });
                if (resp.ok) {
                    let body = await resp.json();
                    console.table(body);

                    window.localStorage.setItem("token", body["access_token"]);
                    window.localStorage.setItem("refresh_token", body["refresh_token"]);

                    window.location.replace("/");

                } else {
                    console.error("failed token");
                    console.error(await resp.text());
                }
            } catch (e) {
                console.error("???");
                console.error(e);
            }
        })();


        return (
            <div>
                {param.get("code")}
            </div>
        );
    }


}

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(<App/>);